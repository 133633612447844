@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';

body {
  margin: 0;
  
}
body {
  font-family: 'Raleway', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-logo{
  width: 200px;
}
.red-underline{
  background-color: rgb(243, 2, 2);
  height: 3px;
  width: 15%;
  margin-top: 1%;
  margin-bottom: 2%;
  border-radius: 15px;
}
@media only screen and (max-width:768px){
  .nav-logo{
    width: 100px;
  }
}