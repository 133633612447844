.page-header{
  height: 40vh;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.page-header-overlay{
  background-color: #000000cb;
  height: 100%;
  display: flex;
  align-items: center;
}
.page-title{
  font-size: 250%;
  font-weight: 600;
}
.page-subtitle{
  font-weight: 500;
}

.section-title{
  font-size: 200%;
  font-weight: 600;

}
.section-content{
  font-size: 110%;
  padding-bottom: 0;
  text-align: justify;
}