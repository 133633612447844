.call-to-action{
    background-image: url('../Assets/BackdropImages/CallToActionBackdrop.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 44vh;
    background-attachment: fixed;
    
}
.call-to-action-overlay{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #000000a4;
}

.heading h2{
  font-size: 3rem !important;

}
.heading h5{
  color: rgb(243, 2, 2);
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

@media  screen and (max-width:1024px) {
  .heading h2{
    font-size: 1.8rem !important;
  
  }
  .heading h5{
    font-size: 1.2rem !important;
    margin-top: 12px;
  }
  .heading p{
    font-size: 0.8rem !important;
    margin-top: -10px !important;
  }
  .call-to-action{
    height: 47vh;
  }
}

@media screen and (max-width:768px){
  .heading h2{
    font-size: 1.4rem !important;
}
.heading h5{
  font-size: 0.9rem !important;
  margin-top: 12px;
}
.heading p{
  font-size: 0.6rem !important;
  margin-top: -10px !important;
}
}

/* home.css */
/* home.css */
.hero-carousel {
    position: relative;
  }
  
  .hero-item {
    position: relative;
    height: 50vh !important; /* Set the desired height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  
  .hero-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);     /* Black overlay with 50% opacity */
  }
  .hero-item::after{
    color: white;
  }
  
  .hero-content {
    text-align: center;
  }
  
  .hero-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Owl Carousel Navigation Arrows */
  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  
  .owl-nav button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 2rem;
    color: #fff;
    background: transparent;
    border: none;
  }
  
  .owl-prev {
    left: 10px;
  }
  
  .owl-next {
    right: 10px;
  }
  
  /* Owl Carousel Dots Pagination */
  .owl-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .owl-dot.active {
    background-color: #007bff;
  }
  

  /* home.css */
.hero-carousel {
    position: relative;
  }
  
  .hero-item {
    position: relative;
    height: 400px; /* Set the desired height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  
  .hero-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  }
  
  .hero-content {
    text-align: center;
    z-index: 1; /* Ensure the text is above the overlay */
  }
  
  .hero-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Owl Carousel Navigation Arrows */
  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  
  .owl-nav button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 2rem;
    color: #fff;
    background: transparent;
    border: none;
  }
  
  .owl-prev {
    left: 10px;
  }
  
  .owl-next {
    right: 10px;
  }
  
  /* Owl Carousel Dots Pagination */
  .owl-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .owl-dot.active {
    background-color: #007bff;
  }
  .hero-carousel i{
    color: white !important;
  }


  /* home.css */
.carousel {
  display: flex;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 10px; /* Adjust as needed */
}

/* Add any additional styling as per your design */



/* Welcome-Section */
.home-span{
  padding: 4px 16px;
  background-color: rgb(243, 2, 2);
  color: #fff;
  border-radius: 40px;
}
.welcome-span{
  font-size: 1.6rem;
  padding-left: 8px;
}
.welcome-img img{
  transition: all 0.4s ease-in-out;
}
.welcome-img img:hover{
  transform: translate(2ch, 1mm);
}
.icon{
  transform: translate(-100%);
  color: rgb(243, 2, 2);
}
.para{
  transition: all 0.2s ease-in-out;
}
.para:hover{
  transform: translateX(10px);
}
.welcome-section{
width: 100%;
background-image: url(https://img.freepik.com/free-vector/background-line-gradient-luxury-style_483537-3308.jpg?w=1380&t=st=1706365126~exp=1706365726~hmac=785b8764488d7667443af1bea946914c981362538f7ae49121cce541c7fbf776);
}




/* OurExpertise-Section */

.expertise-container{
  background-color: rgb(244, 246, 248);
  height: 320px;
  transform: translate(10%,20%);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media screen and (max-width:1024px){
  .expertise-container{
    transform:none;
    background-color:#fff;
  }
  .expertise-img img{
    margin-top: 50px;
    width: 85%;
  }
}

/* ToolsForJobs-Section */
.toolsforjob-container{
  background-color: rgb(244, 246, 248);
  height: 320px;
  transform: translate(-10%,20%);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media screen and (max-width:1024px){
  .toolsforjob-container{
    transform:none;
    background-color:#fff;
  }
  .toolsforjob-img img{
    margin-top: 50px;
    width: 85%;
  }
}



/* HomeProducts-Section */

.product-col{
  background-image: url(https://media.istockphoto.com/id/1254589507/photo/building-tool-in-a-hardware-store.jpg?s=612x612&w=0&k=20&c=dofilEJ9HYm-EH0MbwVyXBCVV_QhnWxqj8WRlbgPMQo=);
  background-size: cover;
  height: 650px;
 

}
.mrr{
  margin-top: 120px;
}
.product-overlay{
  opacity: 0.9;
}
.main-product h5{
  font-weight: 700;
 line-height: 25px;
}

.product-span{
  background-color: rgb(243, 2, 2);
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 1.2rem;
}
.main-product i{
  margin: 0px 8px;
  color:  rgb(243, 2, 2);
  
}

.hei-dot{
  height: 440px;
}

.heii-bg{
  height: 350px !important;
  background-color: rgb(232, 232, 232)!important;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.font{
  font-size: 15px !important;
  font-weight: bold;
}

.heii-2-content{
  height: 94px !important;
}
.main-product-col{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

@media screen and (max-width:468px){
  .main-product h5{
    font-weight: 700;
   line-height: 20px;
   font-size: 1rem;
  }
  .main-product p{
    font-size: 0.8rem;
    margin: 0px 0px;
  }
  .main-product li{
    font-size: 0.8rem;
  }
.main-product button{
  margin-bottom: 25px;
}
  .product-span{
    font-size: 0.9rem;
  }
.product-overlay{

}  
}