:root {
  --primary-color: rgb(243, 2, 2);
}
.ptb50 {
  padding: 65px 0px !important;
}
.whybg {
  background: #eeeeee;
  width: 100%;
  border-radius: 100px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
 background-image: url(https://img.freepik.com/free-vector/background-line-abstract-gradient-colorful-style_483537-2554.jpg?w=1380&t=st=1706343599~exp=1706344199~hmac=36371f26f725b7e8d4ac81b4f4907d9833d3069c9cfae1021e8caf975966c6cd);
}

.centered {
  text-align: center;
}
.about-card{
 width: 150px;
height: 200px;
padding: 50px 0px;
transition: all 0.5s ease-in-out;
box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.about-card:hover{
  background-color: var(--primary-color);
  color: #fff;
  transform: translate(0.8ch, 0.8mm);
}

 .glow-on-hover:hover .card-title{
  color: white !important;
} 
.glow-on-hover:hover .card-text{
  color: white !important;
}
.glow-on-hover:hover .red-underline{
  background-color: #fff;
}
 
   
  .testi-img{
    width: 150px
  }
  .glowing-card i{
    font-size: 40px;
    padding-bottom: 5%;
  }




 /* Testimonial */
 #our-testimonial{
 background-color: #eeeeee;
 padding: 30px 0px;

 }
 .heading-title h2 {
  font-weight: 600;
}
.darkcolor {
  color: #212331;
  
}

.heading-title > span {
  font-size: 1.25rem;
  display: block;
  text-transform: capitalize;
  color: rgb(243, 2, 2);
}
/*-------------------------------*/
  /* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp, 
.testimonial-wrapp .testimonial-text,
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .quoted, .testimonial-quote{
 position: relative;
 -webkit-transition: all .4s ease-in;
 -o-transition: all .4s ease-in;
 transition: all .4s ease-in;
}
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text {
text-align: center;
}
.testimonial-wrapp .quoted,
.testimonial-wrapp .testimonial-photo {
 display: inline-block;
}
.testimonial-wrapp .quoted {
background: rgb(243, 2, 2);
color: #fff;
font-size: 20px;
line-height: 50px;
height: 50px;
width: 50px;
-webkit-border-radius: 50%;
border-radius: 50%;
 -webkit-transform: translateY(50%);
 -ms-transform: translateY(50%);
 -o-transform: translateY(50%);
 transform: translateY(50%);
z-index: 1;
}
.testimonial-wrapp .testimonial-text {
background: #fff;
padding: 4.25rem 2.5rem;
-webkit-border-radius: 10px;
border-radius: 10px;
border: 1px solid #f1f1f1;
}
.testimonial-wrapp .testimonial-photo {
height: 100px;
width: 100px;
margin-top: -50px;
}
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .testimonial-photo > img{
  -webkit-border-radius: 50%;
  border-radius: 50%;   
}
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp:hover .testimonial-text{
 background: #828282;
}
.testimonial-wrapp:hover .testimonial-text{
 background: rgb(243, 2, 2);
 color: #fff;
}



.testimonial-quote {
text-align: left;
padding-top: 10px;
}
.no-quote .testimonial-quote { 
 text-align: center;
}
.testimonial-quote h3{
 position: relative;
}
.no-quote .testimonial-quote h3::before, 
.no-quote .testimonial-quote h3::after {
 display: none;
}
.testimonial-quote h3::before, .testimonial-quote h3::after {
display: inline-block;
font-size: 17px;
}
.testimonial-quote h3::before {
content: "\f10d";
margin-right: 3px;
-webkit-transform: translateY(-10px);
-ms-transform: translateY(-10px);
-o-transform: translateY(-10px);
transform: translateY(-10px);
}
.testimonial-quote h3::after{
 content: "\f10e";
 margin-left: 3px;
 -webkit-transform: translateY(10px);
-ms-transform: translateY(10px);
-o-transform: translateY(10px);
transform: translateY(10px);
}
.testimonial-quote h6{
 font-weight: 300;
}




