/* product.css */
.card-img-container {
  position: relative;
  overflow: hidden;
  height: 300px;
  /* Set your fixed height */
}

.card-img-top {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  /* Add transition for smooth zoom effect */
}

.card-img-container:hover .card-img-top {
  transform: scale(1.1);
  /* Zoom in effect on hover */
}

.product-card {
  border: 0px !important;
  font-weight: 500 !important;
  font-size: 110% !important;

}

@media only screen and (max-width:768px) {
  .card-img-container {
    height: 175px;
  }
  .col-sm-6{
    flex: auto;
    width: 50% !important;
  }
  .col-xs-12{
    flex: auto;
    width: 100% !important;
  }
}